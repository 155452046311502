.signup2_ui {
    @import "constants";  //Color definitions
    @import "shared";  //Color definitions
    color:$black;
    font-family:"Open Sans", sans-serif;
    .resellerOnly {
        display:none;
    }
    .nonReseller .resellerOnly {  
        display:none;
    }
    .order {
        margin-top: 35px;
        p, label, div.select {
            font-size:13px;
            color:$dgray;
        }
        label {
            font-weight:bold;
        }

        h2 {
            margin-top:0;
        }
        hr {
            border:0;
            height:1px;
            background:$lgray;
            margin:10px 0;
        }
        > div {
            display:inline-block;
            vertical-align:top;
            border:1px solid $lgray;
            box-sizing:border-box;
            padding:15px;
            background:#f3f3f3;
            &.base {
                width:calc(33% - 20px);
                margin-right:10px;
                .half {
                    &.right {
                        border-left:1px solid $lgray;
                    }
                }
                .row.pay {
                    text-align:right;
                }
                .features p {
                    margin:10px 0;
                }
            }
            &.pro {
                width:calc(66% - 20px);
                margin-left:10px;
                .row {
                    text-align:center;
                }
                > p:not(.sectionTitle) {
                    margin:25px 0;
                }

                p.title {
                    font-weight:bold;
                    margin:20px 0 5px 10px;
                }
                .features .row {
                    text-align:left;
                    img {width:49px;}
                }
                /*button {
                    font-size:35px;
                    padding:15px 100px;
                }*/
            }
        }
        ._33 {
            border-left:1px solid $lgray;
            &:first-child {
                border-left:none;
            }
        }

        p.variable, p.legend {
            text-align:center;
            color:$black;
            text-transform:uppercase;
            margin:0;
            &.variable {
                font-size:40px;
                font-weight:bold;  
                line-height:40px;
                &.small {
                    text-transform:none;
                    font-size:30px;
                }
            }
            &.legend {
                font-size:14px;
            }
        }
        p.sectionTitle {
            text-align:center;
        }
        button {
            width:100%;
            font-size:30px;
            line-height:42px;
            margin:15px auto 19px;
            display:block;
        }
    }
    
    .features {
        p {
            font-size:15px;
            &:last-child {
                margin-bottom:0;
            }
            img {
                vertical-align:middle;
                margin-right:10px;
                width:48px;
            }
        }

    }

    #AddEmMarketing {
        float:right;
        position:relative;
        top:-77px;
        &:active {
            top:-73px;
        }
    }
    table#service-list {
        td, th{
            text-align:center;
            vertical-align:middle;
        }
        td:last-child {
            min-width:130px; 
            
            button {
                padding:6px 10px;
                &.dropdown {
                    margin-left:4px;
                }
                img{
                    width:30px;
                    margin:-8px;
                }
            }
            div.note {
                position:absolute;
                right:0;
                top:0;
                bottom:0;
                span.noteIcon {
                    background: #91BB5B; 
                    display: inline; 
                    padding: 3px; 
                    border-radius: 0px 10px 10px 0px; 
                    position: absolute; 
                    right: -21px; 
                    top: calc(50% - 14px); 
                    cursor: pointer;
                    img {
                       width: 15px; 
                       position: relative; 
                       top: 2px; 
                    } 
                 }
                .inputWrapper {
                    display:none;
                    position:absolute;
                    background: $higreen;
                    padding: 6px 6px 4px 6px;
                    border-radius: 5px;
                    width: 300px;
                    z-index:2;
                    left:-300px;
                    top: calc(50% - 84px); 
                    box-sizing:border-box;
                    box-shadow:4px 4px 4px gray;
                    p {
                        font-size: 12px;
                        color: white;
                        text-align: left;
                        margin: 0 0 0 1px;
                        button {
                            float:right;
                            box-shadow: 0 2px 0 $gray;
                            font-size:inherit;
                            padding:2px;
                            &:active {
                                box-shadow:none;
                                position:relative;
                                top:2px;
                            }
                            img {
                                display:none;
                                width:12px;
                                margin:0 10px;
                            }
                            &.loading {
                                span {
                                    display:none;
                                }
                                img {
                                    display:inline;
                                }
                            }
                        }
                    }
                    textarea {
                        font-size:13px;
                        display:block;
                        width:100%;
                        padding:5px;
                        height:130px;
                        resize:none;
                        border:1px solid $lgray;
                        border-radius:5px;
                        box-sizing:border-box;
                    }
                }
                &:hover {
                    .inputWrapper {
                        display:block;
                    }
                    span.noteIcon {
                        z-index:3;
                    }

                }  
            }
        }
    }
    #popup-wrapper {

        > div.activate {
            transition:all 1s ease-out;
            height:760px;
            overflow:hidden;
            top:5%;
            overflow-y:auto;
            max-height:90%;
            width:600px;
            &.fedback {
                height:300px;
                top:15%;
                max-height:70%;
                div.data {
                    display:none;
                }
                div#feedback {
                    display:block;
                }
            }
            div#feedback {
                display:none;
                > div {
                    display:none;
                }
                &.activating {
                    .activating {
                        display:block;
                    }
                    h3 {
                        margin:60px 0;
                    }
                }
                &.activated {
                    .activated {
                        display:block;
                    }
                    h3 {
                        margin:60px 0;
                    }
                    button {
                        font-size:22px;
                    }
                }
                &.error {
                    h3 {
                        margin:30px 0;
                    }
                    p {
                        color:$red;
                        margin:40px;
                    }
                    button {
                        position:absolute;
                        width:250px;
                        bottom:30px;
                        left:calc(50% - 125px);
                        font-size:20px;
                        &:active {
                            top:auto;
                            bottom:26px;
                        }
                    }
                    .error {
                        display:block;
                    }
                }
            }
            h3 {
                margin:12px 0;
                font-weight:bold;
            }
            #countriesDropdown {
                width:100%;
                margin-left:0;
                margin-top:6px;
                color:$dgray;
                &.valid { 
                    .options { 
                        border-color:$higreen;
                        &:after {
                            border-top-color:$higreen;
                        }
                    }
                }
                
            }
            div.row {
                display:block;
                font-size:14px;
                //margin:12px 0;
                margin:15px 0;
                text-align:left;
                label, input {
                    display:block;
                }
                > div {
                    display:inline-block;
                    vertical-align:top;
                }
                >p {
                    margin:0 20px;
                    font-weight:bold;
                    font-size:14px;
                }
                p#lockedFields {
                    color:$red;
                    display:none;
                }
                .image {
                    display:none;
                    width:50px;
                    padding:5px 20px;
                    img {
                        width:100%;
                    }
                }
                &.prepopulate {
                    text-align:right;
                    padding-right:20px;
                    font-size:12px;
                    margin:0;
                    color:$higreen;
                    .select, label {
                        vertical-align:middle;
                        margin:6px 0 6px 3px;
                        display:inline-block;
                        width:140px;
                        .options {
                            border-color:$higreen;
                            &:after {
                                border-top-color:$higreen;
                            }
                            .option {
                                padding:1px 5px;
                            }
                        }
                    }
                }
                .field {
                    box-sizing: border-box;
                    padding:0 20px;
                 //   width: calc(100% - 90px);
                    width:100%;
                    position:relative;
                    input, textarea {
                        background: none;
                        font-size:inherit;
                        margin: 0px;
                        margin-top:5px;
                        padding: 4px;
                        box-sizing:border-box;
                        width: 100%;
                        border: 1px solid $gray;
                        border-radius:5px;
                        resize:none;
                        &.valid {
                            border:1px solid $higreen;
                        }
                        &.invalid {
                            border:1px solid $red;
                        }
                        &[disabled] {
                            background:#ddd;
                            cursor:not-allowed;
                        }
                    }
                    &.field50 {
                       // width:calc(50% - 45px);
                       width:50%;
                    }
                    &.fieldTexarea {
                        //width:55%;
                        width:65%;
                    }
                    &.fieldSelect {
                        //width:calc(45% - 90px);
                        width:35%;
                    }
                    #validation-error {
                        background: $red;
                        padding: 5px 10px;
                        border-radius: 5px;
                        position:absolute;
                        right:20px;
                        top:-20px;
                        color: white;
                        &:before {
                            content:"";
                            border-top:10px solid $red;
                            border-left:10px solid transparent;
                            border-right:10px solid transparent;
                            position:absolute;
                            right:25px;
                            bottom:-10px;
                        }
                    }
                }

                &.button {
                    text-align:center; 
                    button {   
                        font-size:20px;

                    }
                }
            }
        }

    }
}